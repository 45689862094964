<template>
  <div class=" page-info-content bank-access-detail">
    <h3 class="title">
      基本信息
    </h3>
    <ul class="basic-information clear">
      <li class="flex-box">
        <span class="li-label">核心企业名称：</span><span class="flex1">{{ detailList.coreCmpName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">统一社会信用代码：</span><span class="flex1">{{ detailList.coreCmpUnicode }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">资金企业名称：</span><span class="flex1">{{ detailList.fundCmpName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">统一社会信用代码：</span><span class="flex1">{{ detailList.fundCmpUnicode }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">商圈编码：</span><span class="flex1">{{ detailList.fundBusiNo }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">资金方产品编码：</span><span class="flex1">{{ detailList.fundProdNo }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">是否有效：</span><span class="flex1">{{ detailList.state==0?'无效':'有效' }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">是否集团模式：</span><span class="flex1">{{ detailList.groupState==0?'否':'是' }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">所属银行类型：</span><span class="flex1">{{ $codeNew(pushBankTypeList,detailList.bankCode) }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">付息方式：</span><span class="flex1">{{ $codeNew(intersTypeList,detailList.interstType + '') }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">版本号：</span><span class="flex1"> {{ $codeNew(bankVersionList,detailList.bankVersion) }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { selectBusinessInfo } from '@/api/memberManagement.js'
export default {
  data() {
    return {
      detailList: {}
    }
  },
  computed: {
    pushBankTypeList() {
      return this.$store.getters.getDictionaryItem('PUSH_BANK_TYPE')
    },
    intersTypeList() {
      return this.$store.getters.getDictionaryItem('INTERS_TYPE')
    },
    bankVersionList() {
      return this.$store.getters.getDictionaryItem('BANK_VERSION')
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      selectBusinessInfo(this.$route.query.id, res => {
        if (res.code === 200) {
          this.detailList = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .bank-access-detail {
    background-color: #fff;
    padding: 14px 18px;

    .title {
      font-weight: 400;
      padding: 0 18px;
      margin: 0 -18px;
      font-size: 16px;
      line-height: 50px;
      height: 50px;
      border-bottom: 1px solid #EEF1F4;

    }

    .basic-information {
      padding-top: 20px;

      li {
        float: left;
        width: 40%;
        font-size: 14px;
        color: #606266;
        padding-bottom: 10px;
        margin-right: 10px;
        line-height: 30px;
        word-break: break-all;
        &.width100 {
          width: 100%;
          word-break: break-all;
        }

        .li-label {
          text-align: right;
          min-width: 112px;
          font-weight: 600;
        }

      }
    }
  }
</style>
